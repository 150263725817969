import { Toast } from 'antd-mobile';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorModal } from '@/components';
import i18n from 'i18next';

enum StatusCode {
	FileExtensionNotSupport = 513,
}

const headers: Readonly<Record<string, string | boolean>> = {
	Accept: 'application/json',
	'Content-Type': 'application/json; charset=utf-8',
	'Cache-Control': 'no-cache, no-store, must-revalidate',
	'Pragma': 'no-cache',
	'Expires': '0'
};

const fileUploadheaders: Readonly<Record<string, string | boolean>> = {
	'Content-Type': 'multipart/form-data',
};

const injectTokenAndLanguage = (config: any): any => {
	const token = localStorage.getItem('tokenOneAPJChampionLeads') || 'null';
	const langCode = localStorage.getItem('langCode');
	const tokenArr = JSON.parse(token);

	const headers: any = {
		Authorization: '',
		/* This is set in formConfirm.submissionError, and this message has to be set in english for a successful response */
		customErrorTitle: 'Something went wrong, please try again.',
	};
	if (tokenArr && tokenArr.length > 1) {
		const [, idToken] = tokenArr;
		headers.Authorization = `Bearer ${idToken}`;
	}
	langCode ? (headers.langCode = langCode) : null;

	!config.headers?.NotshowCustomLoading &&
		Toast.show({
			icon: 'loading',
			duration: 0,
			content: i18n.t('formCommon.submiting') || i18n.t('formCommon.loading'),
			maskClickable: false,
		});
	config.headers = { ...config.headers, ...headers };
	return config;
};

class Http {
	private instance: AxiosInstance | null = null;

	private get http(): AxiosInstance {
		return this.instance != null ? this.instance : this.initHttp();
	}

	initHttp() {
		const http = axios.create({
			baseURL: process.env.REACT_APP_BASE_API,
			headers,
		});

		http.interceptors.request.use(injectTokenAndLanguage, (error) => Promise.reject(error));

		http.interceptors.response.use(
			(response) => {
				Toast.clear();
				//console.log('response', response);
				if (response?.status == 200) {
					if (response.data.code === '000000' && response.data?.data) {
						return response.data.data;
					} else if (response.data.code === '100401') {
						window.location.href = process.env.REACT_APP_REDIRECT_LEAD_URL || '';
					} else {
						const customErrorTitle = response.data.message;
						const errorCode = response.data.code;
						const errorApi = response.config.url?.split('/') || [];

						let responseMessage: any = '';
						if (customErrorTitle) {
							/* This is the translation for response.config.header.customErrorTitle, and this message can be shown in the language chosen by the user */
							responseMessage = `Something went wrong ${errorApi[errorApi.length - 1 || 0]} API failed. The error code is ${errorCode}.`;
						} else {
							responseMessage = this.getErrorMsg(response.data.status);
						}
						ErrorModal.alert({ title: responseMessage });
						return Promise.reject(responseMessage).catch((err) =>
							console.log('Error during submission, bad response', err, response)
						);
					}
				}
			},
			(error) => {
				Toast.clear();
				if (error.response?.status === 401) {
					window.location.href = process.env.REACT_APP_REDIRECT_LEAD_URL || '';
				} else if (error.response?.status === 504) {
					ErrorModal.alert({
						title:'We are currently facing some network issue please try again later',
					});
				}else
				/* Hardcoding error texts as a fallback */
					ErrorModal.alert({
						title: i18n.t('formCommon.defaultErrMsg') || 'Something went wrong, please try again.',
					});
				return Promise.reject(error).catch((err) =>
					console.log('Error:', err.message, err)
				);
			}
		);

		this.instance = http;
		return http;
	}

	request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
		return this.http.request(config);
	}

	get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
		return this.http.get<T, R>(url, config);
	}

	post<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.post<T, R>(url, data, config);
	}

	put<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.put<T, R>(url, data, config);
	}

	delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
		return this.http.delete<T, R>(url, config);
	}

	fileUpload<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.http.post<T, R>(url, data, {
			...config,
			headers: fileUploadheaders,
		});
	}

	private getErrorMsg(status: string | number) {
		/* Hardcoding error texts as a fallback */
		switch (status) {
			case StatusCode.FileExtensionNotSupport: {
				return (
					i18n.t('productGenericForm.attachmentTypeErrMsg') ||
					'Sorry, the attachment type is not supported'
				);
			}
			default:
				return i18n.t('formCommon.defaultErrMsg') || 'Something went wrong, please try again.';
		}
	}
}

export interface ResponsePageType {
	pageNo: number;
	pageSize: number;
	count: number;
}

export interface ResponseBaseType<T> {
	message: string;
	data: T;
	code: string;
	page: ResponsePageType;
}

export const http = new Http();
